<template>
  <div class="p-3" v-if="!showItems">
    <!-- Title -->
    <div class="flex items-center gap-2">
      <div class="font-semibold flex gap-2">
        <div>{{ colli.name }}</div>
      </div>

      <div
         data-testId="info-icon"
        class="cursor-pointer"
        v-if="colli.data != null && colli.data.data != null"
        @click.stop="$emit('showAllData')"
      >
        <div><info-icon :fillClass="'fill-primary'"></info-icon></div>
      </div>

      <div
        class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest "
      >
        <div
          class="cursor-pointer"
          data-testId="edit-colli"
          @click.stop="$emit('editColli')"
        >
          <div class="ml-2"><edit-icon /></div>
        </div>
      </div>
    </div>

    <div
      v-if="
        colli.data != null &&
          colli.data.data != null &&
          colli.data.dataModel != null &&
          colli.data.dataModel.dataFields != null
      "
      class="flex flex-row flex-wrap justify-start mt-3 gap-8"
    >
      <div
        v-for="dataField of colli.data.dataModel.dataFields"
        :key="dataField"
      >
        <div class=" flex text-sm gap-9 sm:gap-20">
          <div>
            <div class="text-sm text-blue">
              {{ dataField.key }}
            </div>
            <div
              class="text-md mt-2"
              v-if="colli.data.data[dataField.key] === true"
            >
              <right-icon />
            </div>
            <div
              class="text-md mt-2"
              v-else-if="colli.data.data[dataField.key] === false"
            >
              <false-icon />
            </div>
            <div class="text-md" v-else>
              {{
                colli.data.data[dataField.key] == null ||
                colli.data.data[dataField.key] == ""
                  ? "-"
                  : colli.data.data[dataField.key]
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--- ---------------------- product carrier list  ------------------------------>
  <div class="bg-white-dark rounded-md">
    <div class="bg-white-dark rounded-md w-full p-2 mt-2" v-if="showItems">
      <div class="p-2 flex items-center">
        <div class="flex flex-col">
          <div class=" flex  font-semibold  box-content md:text-md gap-1">
            <div class="font-semibold">{{ colli.name }}</div>
          </div>
          <div class="text-xs md:text-sm hidden sm:block">
            <div class=" flex items-center text-sm gap-2">
              <div>{{ $t("channel") }}: {{ "23.09.2020" }} |</div>
              <div>{{ $t("group") }}: {{ " Gruppe 2" }}</div>
            </div>
          </div>
        </div>
        <div class="ml-auto md:mr-7">
          <div class="flex items-center space-x-6 ">
            <edit-icon class="cursor-pointer" @click="$emit('editColli')" />
            <trash-icon class="cursor-pointer" @click="$emit('deleteColli')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import EditIcon from "../../../components/icons/EditIcon.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";

export default defineComponent({
  name: "ProductCarrierInfoContainer",
  components: { EditIcon, TrashIcon },

  /** Emits :  */

  emits: [
    "open",
    "editColli",
    "showAllData",
    "showAllPositions",
    "deleteColli",
  ],

  /** Props */
  props: {
    administrationView: {
      type: Boolean,
      default: false,
    },
    colli: {
      type: Object,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
