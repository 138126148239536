<template>
  <div>
    <modal-backdrop
      :isVisible="isVisible"
      :isDrawer="isDrawer"
      @closeModal="closeModal()"
    />
    <height-transition>
      <div
        v-if="isVisible"
        ref="modal"
        class="small-bottom-up-modal fixed bottom-0 left-0 md:left-1/4 right-0 md:right-1/4  self-center h-3/4 w-auto bg-white rounded-xl rounded-b-none flex flex-col items-center justify-items-start"
        :class="[
          state.interval ? '' : 'transition-all',
          isDrawer ? 'h-1/2 mb-12' : '',
        ]"
      >
        <button
        data-testId="close-middle-modal" 
          class="small-bottom-up-modal__handlerbox absolute p-3 cursor-pointer top-0"
          @mousedown="calculateHeight"
          @touchstart="calculateHeight"
        >
          <input ref="handlerBox" hidden />
          <div
            class="handlerbox__handler w-8 rounded-sm h-1 transition-bg-color bg-grey"
          ></div>
        </button>
        <div
          class="w-full h-full flex flex-row justify-center overflow-y-auto scrollbar pr-1"
        >
          <div class="modalcontent w-full">
            <h2 class="font-semibold" :class="{ 'text-center': isTitleCenter }">
              {{ title }}
            </h2>
            <slot></slot>
          </div>
        </div>
      </div>
    </height-transition>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import HeightTransition from "../transitions/HeightTransition.vue";
import ModalBackdrop from "./ModalBackdrop.vue";

export default defineComponent({
  name: "BottomUpModal",
  components: { HeightTransition, ModalBackdrop },
  emits: ["closeModal"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
    isDrawer: {
      type: Boolean,

      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    isTitleCenter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const store = useStore();
    /**
     *  Refs
     */
    const modal = ref(null);

    /**
     *  State
     */
    const state = reactive({
      interval: false,
      countDragSeconds: 0,
      isDownDirection: false,
    });

    /**
     * Functions
     */
    const calculateHeight = function(event) {
      state.interval = true;
      state.countDragSeconds += 1;
      let newModalHeight = 0;
      if (event.changedTouches == null) {
        newModalHeight = window.innerHeight - event.clientY + 14;
      } else {
        newModalHeight =
          window.innerHeight - event.changedTouches[0].clientY + 14;
      }
      if (props.isDrawer) {
        newModalHeight -= 48;
      }
      const oldModalHeight = parseInt(modal.value.style.height.slice(0, -2));

      if (oldModalHeight > newModalHeight) {
        state.isDownDirection = true;
      } else {
        state.isDownDirection = false;
      }
      modal.value.style.height = newModalHeight + "px";
    };

    const validateHeight = function() {
      let closeModal = false;
      if (state.countDragSeconds <= 3 || state.isDownDirection) {
        closeModal = true;
      }
      state.countDragSeconds = 0;
      state.interval = false;
      if (closeModal) {
        ctx.emit("closeModal");
      } else {
        if (!props.isDrawer) {
          modal.value.style.height = "calc(80%)";
        } else {
          modal.value.style.height = "50%";
        }
      }
    };
    function REMOVE_ERROR_ID() {
      store.commit(`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_ERROR_IDS}`, {});
    }
    function closeModal() {
      REMOVE_ERROR_ID();
      ctx.emit("closeModal");
    }

    /**
     * Mounted
     */
    onMounted(() => {
      document.addEventListener("mouseup", () => {
        if (state.interval) {
          validateHeight();
        }
      });
      document.addEventListener("mousemove", (event) => {
        if (state.interval) {
          calculateHeight(event);
        }
      });
      document.addEventListener("touchend", () => {
        if (state.interval) {
          validateHeight();
        }
      });
      document.addEventListener("touchmove", (event) => {
        if (state.interval) {
          calculateHeight(event);
        }
      });
    });

    return { state, calculateHeight, validateHeight, modal, closeModal };
  },
});
</script>

<style lang="scss">
.small-bottom-up-modal {
  z-index: 1000000;
  height: calc(80%);
  padding: 70px 10px 20px 20px;
  .modalcontent {
    max-width: 800px;
  }
  .small-bottom-up-modal__handlerbox:hover {
    .handlerbox__handler {
      @apply bg-white-dark;
    }
  }
}
</style>
