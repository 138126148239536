<template>
  <bottom-up-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeModal')"
    :title="
      GET_CUSTOMER_COLLI != null && GET_CUSTOMER_COLLI.id != null
        ? $t('edit_product_carrier')
        : $t('add_product_carrier')
    "
  >
    <!--- colli form -->
    <product-carrier-form
      @closeModal="$emit('closeModal')"
      @setBack="set_back()"
      @updateColli="UPDATE_COLLI()"
      @createColli="CREATE_COLLI()"
    />

    <div class="mt-3 w-full h-px bg-primary-light"></div>

    <!--- header -->
    <div>
      <div class="font-semibold text-lg mt-8 mb-4">
        {{ $t("product_carrier") }}
      </div>
      <!--- search input -->
      <searchbar
        :id="'search'"
        v-model="state.search"
        :label="$t('search')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="w-1/2 bg-white mb-3"
        :placeHolder="$t('designation')"
        @update:modelValue="debounceSearchCollis"
      />
    </div>

    <!--- content -->
    <div
      v-if="GET_CUSTOMER_COLLIS != null && GET_CUSTOMER_COLLIS.length > 0"
      class="md:w-full h-full transition-all overflow-auto scrollbar scrollbar-dark pr-1 md:mr-1"
      id="colliScrollBox"
      :class="GET_CUSTOMER_COLLIS ? 'w-full' : 'w-full'"
    >
      <product-carrier-info-container
        v-for="colli of GET_CUSTOMER_COLLIS"
        :key="colli.id"
        :colli="colli"
        :showItems="true"
        @deleteColli="delete_colli(colli)"
        @editColli="edit_colli(colli)"
      />
      <div class="w-full flex justify-center items-center">
        <infinite-loading
          :scrollBoxId="'colliScrollBox'"
          :isActive="!GET_ALL_COLLIS_LOADED"
          :emptyText="$t('no_more_collis')"
          @load="loadCollis()"
        />
      </div>
    </div>

    <!--empty feedback-->
    <div v-else>
      <empty-list-feedback
        class="pt-10 flex justify-center items-center"
        v-if="GET_CUSTOMER_COLLIS == null || GET_CUSTOMER_COLLIS.length == 0"
        :text="$t('no_collis')"
      ></empty-list-feedback>
    </div>

    <!--- delete modal -->
    <delete-product-carrier-modal
      :isVisible="state.openDeleteModal"
      @closeModal="state.openDeleteModal = false"
      @delete="DELETE_COLLI()"
    />
  </bottom-up-modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";

import Searchbar from "../../../components/layout/Searchbar.vue";
import { getData } from "../../../common/dataHelper";

import Debouncer from "../../../common/debouncer";
import {
  AUTH_TYPES,
  COLLI_POSITIONS_TYPES,
  CUSTOMERS_TYPES,
  CUSTOMER_CHANNELS_TYPES,
  CUSTOMER_COLLIS_TYPES,
  DATA_FIELDS_TYPES,
  DATA_MODELS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { computed, watch } from "@vue/runtime-core";
import InfiniteLoading from "../../../components/global/InfiniteLoading.vue";
import ProductCarrierForm from "../../finders/components/ProductCarrierForm.vue";
import DeleteProductCarrierModal from "../../finders/modals/DeleteProductCarrierModal.vue";
import ProductCarrierInfoContainer from "../../finders/components/ProductCarrierInfoContainer.vue";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";

export default {
  components: {
    BottomUpModal,
    ProductCarrierForm,
    DeleteProductCarrierModal,
    Searchbar,
    InfiniteLoading,
    ProductCarrierInfoContainer,
    EmptyListFeedback,
  },

  emits: ["closeModal"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    fetchProp: {
      type: Boolean,
      default: true,
    },
    placeHolder: {
      type: String,
    },
    colli: {
      type: Object,
    },
  },

  setup(props) {
    const store = useStore();
    /**
     *  Refs
     */

    /**
     *  State
     */
    const state = reactive({
      debouncer: new Debouncer(),
      isOpen: false,
      openDeleteModal: false,
      changeTitle: false,
      search: "",
    });

    /*
     * Computeds
     */
    const GET_ALL_COLLIS_LOADED = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_ALL_COLLIS_LOADED}`
      ];
    });

    const GET_CUSTOMER_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`
      ];
    });

    const GET_CUSTOMER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLI}`
      ];
    });
    const GET_DELETE_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_DELETE_CUSTOMER_COLLI}`
      ];
    });
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredCollis = computed(() => {
      if (GET_CUSTOMER_COLLIS.value == null) {
        return;
      }
      return GET_CUSTOMER_COLLIS.value.filter((colli) => {
        if (state.search == "" || state.search == null) {
          return true;
        }
        if (
          colli.name != null &&
          colli.name.toLowerCase().match(state.search.toLowerCase())
        ) {
          return true;
        }
        const dataAttributes = getData(colli, "data.data");

        if (dataAttributes) {
          for (const key in dataAttributes) {
            if (Object.hasOwnProperty.call(dataAttributes, key)) {
              const element = dataAttributes[key];
              if (
                element &&
                element
                  .toString()
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              )
                return true;
            }
          }
        }

        return false;
      });
    });

    /**
     * Functions
     */

    const DELETE_COLLI = async () => {
      const colliData = {
        customer_id: GET_DELETE_COLLI.value.customer_id,
        id: GET_DELETE_COLLI.value.id,
        customer_colli: GET_DELETE_COLLI.value,
      };
      if (GET_DELETE_COLLI.value == null || GET_DELETE_COLLI.value.id == null) {
        return;
      }

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "delete"
      );
      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.DELETE_CUSTOMER_COLLI}`,
        colliData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "delete"
      );
      state.openDeleteModal = false;
      set_back();
    };
    const GET_PAGE = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_PAGE}`
      ];
    });

    function LOAD_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: {
          archived: true,
          search: state.search,
          page: GET_PAGE.value + 1,
        },
      };

      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }
    function FETCH_CUSTOMER_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: { archived: true },
      };

      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }

    function FETCH_COLLI_POSITIONS() {
      store.dispatch(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.FETCH_COLLI_POSITIONS}`,
        {
          id: GET_USER.value.customer_id,
        }
      );
    }
    function FETCH_CUSTOMER() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMERS}/${CUSTOMERS_TYPES.FETCH_CUSTOMER}`,
        {
          customer_id: GET_USER.value.customer_id,
        }
      );
    }

    function FETCH_DATA_MODELS() {
      store.dispatch(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.FETCH_DATA_MODELS}`,
        GET_USER.value.customer_id
      );
    }

    function FETCH_DATA_FIELDS() {
      store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.FETCH_DATA_FIELDS}`,
        GET_USER.value.customer_id
      );
    }
    function FETCH_CHANNELS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.FETCH_CUSTOMER_CHANNELS}`,
        GET_USER.value.customer_id
      );
    }

    const SET_CUSTOMER_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLI}`,
        colli
      );
    };
    const SET_DELETE_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.SET_DELETE_CUSTOMER_COLLI}`,
        colli
      );
    };

    // Update Colli
    const UPDATE_COLLI = async (input_id) => {
      if (
        GET_CUSTOMER_COLLI.value == null ||
        GET_CUSTOMER_COLLI.value.id == null
      ) {
        return;
      }
      const colliData = {
        customer_id: GET_CUSTOMER_COLLI.value.customer_id,
        id: GET_CUSTOMER_COLLI.value.id,
        create_colli: GET_CUSTOMER_COLLI.value,
      };
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        input_id
      );

      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.UPDATE_CUSTOMER_COLLI}`,
        colliData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        input_id
      );
      set_back();
    };

    // CREATE COLLI
    const CREATE_COLLI = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "create_colli"
      );
      const response = await store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.CREATE_CUSTOMER_COLLI}`,
        GET_USER.value.customer_id
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "create_colli"
      );
      if (response.status == 201) {
        set_back();
      }
    };

    const delete_colli = (colli) => {
      SET_DELETE_COLLI(colli);
      state.openDeleteModal = true;
    };
    const edit_colli = (colli) => {
      SET_CUSTOMER_COLLI(colli);
      state.openModal = true;
      state.changeTitle = true;
    };

    const set_back = () => {
      SET_CUSTOMER_COLLI({});
      state.isOpen = false;
      state.openModal = false;
    };

    function loadCollis() {
      LOAD_COLLIS();
    }
    const debounceSearchCollis = () => {
      state.debouncer.debounce(SEARCH_IN_CUSTOMER_COLLIS);
    };
    function SEARCH_IN_CUSTOMER_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: {
          archived: true,
          search: state.search,
          page: 1,
        },
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            FETCH_DATA_MODELS(GET_USER.value.customer_id);
            FETCH_DATA_FIELDS(GET_USER.value.customer_id);
            FETCH_CHANNELS(GET_USER.value.customer_id);
            FETCH_COLLI_POSITIONS(GET_USER.value.customer_id);
            FETCH_CUSTOMER(GET_USER.value.customer_id);

            if (props.fetchProp == true) {
              FETCH_CUSTOMER_COLLIS(GET_USER.value.customer_id);
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
      store,
      UPDATE_COLLI,
      CREATE_COLLI,
      delete_colli,
      edit_colli,
      set_back,
      filteredCollis,
      GET_CUSTOMER_COLLIS,
      GET_CUSTOMER_COLLI,
      DELETE_COLLI,
      GET_ALL_COLLIS_LOADED,
      loadCollis,
      debounceSearchCollis,
    };
  },
};
</script>
