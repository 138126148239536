<template>
  <div>
    <div class="flex items-center mb-4 gap-3 w-full">
      <!-- search input --->
      <searchbar
        data-testId="archiv-search"
        :id="'search'"
        :label="$t('search')"
        v-model="state.search"
        :labelClass="'bg-white-dark'"
        :activeColor="'primary'"
        class="w-full sm:w-1/3 "
        :placeHolder="$t('search')"
        @update:modelValue="debounceSearchCollis"
      />
    </div>
    <div
      v-if="GET_CUSTOMER_COLLIS != null && GET_CUSTOMER_COLLIS.length > 0"
      class="md:w-full h-full transition-all overflow-auto scrollbar scrollbar-dark pr-1 md:mr-1"
      id="archiveScrollBox"
      :class="GET_CUSTOMER_COLLIS ? 'w-full' : 'w-full'"
    >
      <div
        :id="'colli-' + colli.id"
        class="bg-white cursor-pointer rounded-md h-auto flex-1 gap-8 mb-3  hover:border-grey-fontgrey"
        :class="
          GET_CUSTOMER_COLLI.id == colli.id
            ? 'border-t-4 border-primary'
            : 'border border-transparent'
        "
        v-for="colli of GET_CUSTOMER_COLLIS"
        :key="colli.id"
        :colli="colli"
        @click="edit_colli(colli)"
      >
        <!--  ON ACTIVE   SHOW IF ...  -->

        <archive-container
          :colli="colli"
          :administrationView="true"
          @editColli="edit_colli(colli)"
          @showAllData="state.colli = colli"
        />
      </div>
      <div class="w-full flex justify-center items-center">
        <infinite-loading
          :scrollBoxId="'archiveScrollBox'"
          :isActive="!GET_ALL_COLLIS_LOADED"
          :emptyText="$t('no_more_collis')"
          @load="loadCollis()"
        />
      </div>
    </div>

    <!--empty feedback-->
    <div v-else>
      <empty-list-feedback
        class="pt-10 flex justify-center items-center"
        v-if="GET_CUSTOMER_COLLIS == null || GET_CUSTOMER_COLLIS.length == 0"
        :text="$t('no_collis')"
      ></empty-list-feedback>
    </div>

    <!--- add modal -->
    <add-archived-colli-modal
      :isVisible="state.openModal"
      @closeModal="set_back()"
    />

    <product-carrier-data-modal
      v-if="state.colli != null && state.colli.id != null"
      :colli="state.colli"
      :isVisible="state.colli != null && state.colli.id != null"
      @closeModal="state.colli = {}"
    ></product-carrier-data-modal>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Searchbar from "../../../components/layout/Searchbar.vue";
import {
  AUTH_TYPES,
  CUSTOMER_COLLIS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { getData } from "../../../common/dataHelper";

import ProductCarrierDataModal from "../../finders/modals/ProductCarrierDataModal.vue";
import ArchiveContainer from "../components/ArchiveContainer.vue";
import Debouncer from "../../../common/debouncer";
import InfiniteLoading from "../../../components/global/InfiniteLoading.vue";
import AddArchivedColliModal from "../components/AddArchivedColliModal.vue";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
export default defineComponent({
  components: {
    ArchiveContainer,
    Searchbar,
    ProductCarrierDataModal,
    InfiniteLoading,
    AddArchivedColliModal,
    EmptyListFeedback,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      debouncer: new Debouncer(),
      search: "",
      openModal: false,
    });

    /*
     * Computeds
     */
    const GET_CUSTOMER_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`
      ];
    });
    const GET_CUSTOMER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLI}`
      ];
    });

    const filteredCollis = computed(() => {
      if (GET_CUSTOMER_COLLIS.value == null) {
        return;
      }
      return GET_CUSTOMER_COLLIS.value.filter((colli) => {
        if (state.search == "" || state.search == null) {
          return true;
        }
        if (
          colli.name != null &&
          colli.name.toLowerCase().match(state.search.toLowerCase())
        ) {
          return true;
        }
        const dataAttributes = getData(colli, "data.data");

        if (dataAttributes) {
          for (const key in dataAttributes) {
            if (Object.hasOwnProperty.call(dataAttributes, key)) {
              const element = dataAttributes[key];
              if (
                element &&
                element
                  .toString()
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              )
                return true;
            }
          }
        }

        return false;
      });
    });

    const GET_USER = computed(() => {
      return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
    });
    const GET_PAGE = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_PAGE}`
      ];
    });
    const GET_ALL_COLLIS_LOADED = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_ALL_COLLIS_LOADED}`
      ];
    });
    /** Functions */

    const SET_CUSTOMER_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLI}`,
        colli
      );
    };

    const import_csv_file = () => {
      state.openImportModal = true;
    };
    const add_product_carrier = () => {
      SET_CUSTOMER_COLLI({});
      state.openModal = true;
    };
    const set_back = () => {
      SET_CUSTOMER_COLLI({});
      state.openModal = false;
    };
    const edit_colli = (colli) => {
      SET_CUSTOMER_COLLI(colli);
      state.openModal = true;
    };

    function FETCH_CUSTOMER_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: {
          archived: true,
          search: state.search,
          page: GET_PAGE.value + 1,
        },
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }
    function SEARCH_IN_CUSTOMER_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: {
          archived: true,
          search: state.search,
          page: 1,
        },
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }

    const debounceSearchCollis = () => {
      state.debouncer.debounce(SEARCH_IN_CUSTOMER_COLLIS);
    };
    function loadCollis() {
      FETCH_CUSTOMER_COLLIS();
    }

    /**
     *  Mounted
     */

    return {
      store,
      state,
      route,
      add_product_carrier,
      edit_colli,
      import_csv_file,
      GET_CUSTOMER_COLLIS,
      GET_CUSTOMER_COLLI,
      filteredCollis,
      set_back,
      debounceSearchCollis,
      loadCollis,
      GET_ALL_COLLIS_LOADED,
    };
  },
});
</script>
