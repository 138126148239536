<template>
  <div>
    <bottom-up-modal-2 :isVisible="isVisible" @closeModal="$emit('closeModal')">
      <div
        class="text-lg font-semibold justify-center flex gap-2"
        v-if="colli != null"
      >
        <div class="text-primary">{{ $t("info") }}</div>
        <div class="text-primary">{{ colli.name }}</div>
      </div>
      <div class="mt-5"></div>
      <div
        v-for="dataField of colli.data.dataModel.dataFields"
        :key="dataField"
        class="flex flex-row even:bg-white-dark p-1"
      >
        <!-- class="flex flex-wrap md:flex-nowrap  gap-10 md:gap-24 mt-5 text-xs sm:text-sm " -->

        <div class="text-md  w-1/2">
          {{ dataField.key }}
        </div>
        <div
          class="text-md mt-2"
          v-if="colli.data.data[dataField.key] === true"
        >
          <right-icon />
        </div>
        <div
          class="text-md mt-2"
          v-else-if="colli.data.data[dataField.key] === false"
        >
          <false-icon />
        </div>
        <div class="text-md  w-1/2" v-else>
          {{
            colli.data.data[dataField.key] != null
              ? colli.data.data[dataField.key]
              : "-"
          }}
        </div>
      </div>
    </bottom-up-modal-2>
  </div>
</template>

<script>
import FalseIcon from "../../../components/icons/FalseIcon.vue";
import RightIcon from "../../../components/icons/RightIcon.vue";
import BottomUpModal2 from "../../../components/layout/BottomUpModal2.vue";

export default {
  components: {
    BottomUpModal2,
    RightIcon,
    FalseIcon,
  },

  emits: ["closeModal", "setBack"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
    },
    colli: {
      type: Object,
      required: true,
    },
  },

  setup() {},
};
</script>
